<template>
  <div class="footer">
    <div class="footer-container">
      <div class="footer-content">
        <div class="footer-logo"></div>
        <div class="page-list">
          <div
            class="item"
            v-for="(item, index) of pageList"
            :key="index"
            @click="handlePage(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="contact-us">
          <div>联系我们</div>
          <div class="items">
            <div class="item serve-wrap">
              <div class="mobile">服务热线：95065</div>
              <div class="clock">工作时间：8:00—20:00</div>
            </div>
            <div class="item">
              <div class="mobile">投诉反馈：95065</div>
              <div class="clock">工作时间：8:00—20:00</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-link-copyright">
        <a data-v-6e217632="" target="_blank" href="https://beian.miit.gov.cn/"
          >沪ICP备19030284号-7</a
        >&nbsp;&nbsp;
        <img
          data-v-6e217632=""
          src="https://www.xinye.com/static/img/beian.d0289dc.png"
          style="
            width: 16px;
            height: 16px;
            position: relative;
            margin-right: 2px;
            position: relative;
            bottom: 1px;
          "
        />
        <a
          data-v-6e217632=""
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020200"
          >沪公网安备 31011502020200号</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      pageList: [
        {
          name: "首页",
          path: "/home/",
          key: "home",
        },
        {
          name: "海陆云系统",
          path: "/sealand/",
          key: "sealand",
        },
        {
          name: "信也官网",
          key: "xinye",
          path: "https://www.xinye.com/",
        },
        {
          name: "拍掌柜介绍",
          path: "/introduce/",
          key: "introduce",
        },
      ],
    };
  },
  methods: {
    handlePage(item) {
      if (item.key === "xinye") {
        window.open(item.path, "_blank");
      } else {
        const routeUrl = this.$router.resolve({
          path: item.path,
        });
        window.open(routeUrl.href, "_self");
      }
      const isHome = this.$route.path.includes("home");
      this.$POINT("h5_clk", {
        page: isHome ? "super-官网首页" : "super-海陆系统介绍页",
        tgt_event_id: isHome
          ? "super_homepage_bottomlink"
          : "super_sealand_bottomlink",
        tgt_name: `super-首页底部导航-${item.name}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.footer {
  .setSize(100%, 348px);
  background: #f5f9fa;
  font-size: 12px;
  line-height: 12px;
  &-logo {
    .setSize(100px, 31px);
    .setBgImg("@main/assets/img/pzg_logo@2x.png", center, center, contain, no-repeat);
  }
  &-container {
    .setSize(@section-width, auto);
    min-width: @section-width;
    margin: 0 auto;
  }
  &-content {
    width: 100%;
    .setFlexPos(row, flex-start, flex-start);
    padding: 105px 100px 59px 100px;

    .page-list {
      .setFlexPos(column, flex-start, flex-start);
      padding: 0 100px;
      gap: 20px;
      cursor: pointer;
      position: relative;
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        right: 0;
        width: 0px;
        height: 108px;
        border: 0.5px dashed #7a9cbc;
      }
    }
    .contact-us {
      .setFlexPos(column, flex-start, flex-start);
      padding: 0 100px;
      .items {
        .setFlexPos(row, flex-start, flex-start);
        margin-top: 19px;
        .item {
          position: relative;
          .mobile {
            font-weight: 300;
            margin-bottom: 15px;
            &::before {
              content: "";
              position: relative;
              display: inline-block;
              margin-right: 7px;
              top: 3px;
              .setSize(14px, 14px);
              .setBgImg("@main/assets/img/icon_mobile@2x.png", center, center, contain, no-repeat);
            }
          }
          .clock {
            .mobile();
            &::before {
              .setBgImg("@main/assets/img/icon_clock@2x.png", center, center, contain, no-repeat);
            }
          }

          &:first-child {
            padding-right: 40px;
            margin-right: 40px;
            &::after {
              content: "";
              display: inline-block;
              position: absolute;
              right: 0;
              top: 5px;
              width: 0px;
              height: 42px;
              border: 0.5px dashed #7a9cbc;
            }
          }
        }
      }
    }
  }
  .footer-link-copyright {
    padding-left: 100px;

    a {
      font-size: 12px;
      font-weight: 500;
      color: @noraml-color;
    }
  }
}
</style>
